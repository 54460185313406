@import 'shared/style/Variables.scss';

.login {
  padding: 20px;
  min-height: 100vh;

  .login-form-wrapper {
    padding: 30px !important;
    max-width: 500px;
    border-radius: 10px;
    background-color: white;
    margin: auto;
    text-align: center;


  }
}
.azure-login-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
}
