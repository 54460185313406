@import 'shared/style/Variables.scss';

.footer-wrapper {
  background-color: #f2edf3;
  border-top: 1px solid #dccedf;
  .ant-row {
    margin: 0 !important;
  }

  .secondary-footer {
    padding: 15px 10px;
  }
}

.text-center {
  text-align: center;
}
