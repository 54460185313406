@charset "utf-8";
@import 'shared/style/Variables.scss';

.page-wrapper {
  /* CSS Document */


  body {
    background-color: #f8fafb;
    font-family: 'Ubuntu', sans-serif;
    overflow: hidden;

  }

  .main {
    background-color: #FFFFFF;
    width: 360px;
    margin: 7em auto;
    border-radius: 1em;
    box-shadow: 0 11px 35px 2px rgba(0, 0, 0, 0.14);
    padding: 20px;

  }

  .sign {
    padding-top: 40px;
    color: #8C55AA;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 23px;
  }

  .signp {
    text-align: center;
    font-size: 18px;

  }

  .logo-thb {
    width: 220px;
    margin: 2em auto 1em auto;
    display: block;
  }


  .submit {
    cursor: pointer;
    border-radius: 6px;
    border: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 15px;
    padding-top: 15px;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.4);
    color: #fff;
    background-color: #23b467;
    border-color: #6c63ff;
    width: 260px;
    display: block;
    margin: 5em auto 0 auto;
  }

  .forgot {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: #E1BEE7;
    padding-top: 15px;
  }

  a {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: $primary-color;
    text-decoration: none
  }

  .main-403 .submit {
    margin: 2em auto 0 auto;
  }

  .error403 {
    font-size: 56px;
    margin: 0 0 0 0;
    padding: 0;
    text-align: center;
  }

  .erroricon {
    margin: 10px auto;
    display: block;
    text-align: center;
  }


  /** Ghost ****/
  #ghost {

    position: relative;
    -webkit-animation: linear infinite;
    -webkit-animation-name: run;
    -webkit-animation-duration: 5s;
  }

  @-webkit-keyframes run {
    0% {
      left: 0;
    }
    50% {
      left: 70%;
    }
    100% {
      left: 0;
    }
  }

  /** Ghost ****/

  /************* Wave **********/

  .ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #23b467;
    z-index: -1;
  }

  .wave {

    background: url("../images/wave.svg") repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
  }

  .wave:nth-of-type(2) {
    top: -175px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
  }

  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }

  @keyframes swell {
    0%, 100% {
      transform: translate3d(0, -25px, 0);
    }
    50% {
      transform: translate3d(0, 5px, 0);
    }
  }

  @media (max-width: 600px) {
    .main {
      border-radius: 0px;
    }

  }
}
