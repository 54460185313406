@import './Variables.scss';

.page-wrapper {
  padding: 20px;
}

.form-wrapper {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}

.demo-loadmore-list {
  max-height: 400px;
  overflow-y: auto;
}

//.page-view {
//  width: 100vw;
//  height: 100vh;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//}
